import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f344487c = () => interopDefault(import('../pages/aviso-legal.vue' /* webpackChunkName: "pages/aviso-legal" */))
const _5e35a4e0 = () => interopDefault(import('../pages/ayuda.vue' /* webpackChunkName: "pages/ayuda" */))
const _b9161ba2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _14a9b214 = () => interopDefault(import('../pages/cambiar-contrasena.vue' /* webpackChunkName: "pages/cambiar-contrasena" */))
const _76a69105 = () => interopDefault(import('../views/CategoriesBridge.vue' /* webpackChunkName: "" */))
const _4c5f08a4 = () => interopDefault(import('../views/Homepage.vue' /* webpackChunkName: "" */))
const _b797a574 = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _092ba5d9 = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _2a9a3d83 = () => interopDefault(import('../pages/contrasena-actualizada-exitosamente.vue' /* webpackChunkName: "pages/contrasena-actualizada-exitosamente" */))
const _aecaa616 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _f74c6798 = () => interopDefault(import('../pages/cupones/index.vue' /* webpackChunkName: "pages/cupones/index" */))
const _aad3dc06 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _f14b3280 = () => interopDefault(import('../pages/dashboard/index/index.vue' /* webpackChunkName: "pages/dashboard/index/index" */))
const _3713a5ab = () => interopDefault(import('../pages/dashboard/index/actividad.vue' /* webpackChunkName: "pages/dashboard/index/actividad" */))
const _9a38e3ae = () => interopDefault(import('../pages/dashboard/index/ajustes.vue' /* webpackChunkName: "pages/dashboard/index/ajustes" */))
const _19184989 = () => interopDefault(import('../pages/dashboard/index/chats.vue' /* webpackChunkName: "pages/dashboard/index/chats" */))
const _75ea8d0d = () => interopDefault(import('../pages/dashboard/index/descuentos.vue' /* webpackChunkName: "pages/dashboard/index/descuentos" */))
const _4f1d275a = () => interopDefault(import('../pages/dashboard/index/guardados.vue' /* webpackChunkName: "pages/dashboard/index/guardados" */))
const _61a4910b = () => interopDefault(import('../pages/dashboard/index/monedas.vue' /* webpackChunkName: "pages/dashboard/index/monedas" */))
const _26337d91 = () => interopDefault(import('../pages/dashboard/index/premios.vue' /* webpackChunkName: "pages/dashboard/index/premios" */))
const _2cf6a867 = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _b17f239a = () => interopDefault(import('../pages/expirados.vue' /* webpackChunkName: "pages/expirados" */))
const _338130fc = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _251060d4 = () => interopDefault(import('../pages/foros/index.vue' /* webpackChunkName: "pages/foros/index" */))
const _56772d92 = () => interopDefault(import('../pages/gratis.vue' /* webpackChunkName: "pages/gratis" */))
const _59fe9f6e = () => interopDefault(import('../pages/inicio-sin-contrasena.vue' /* webpackChunkName: "pages/inicio-sin-contrasena" */))
const _59fa4f62 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ff4a64b8 = () => interopDefault(import('../views/InstallExtension.vue' /* webpackChunkName: "" */))
const _10d63f1b = () => interopDefault(import('../views/StoresBridge.vue' /* webpackChunkName: "" */))
const _432a7398 = () => interopDefault(import('../pages/mensajeria/index.vue' /* webpackChunkName: "pages/mensajeria/index" */))
const _481f5258 = () => interopDefault(import('../pages/notificaciones.vue' /* webpackChunkName: "pages/notificaciones" */))
const _3b00366e = () => interopDefault(import('../pages/novedades/index.vue' /* webpackChunkName: "pages/novedades/index" */))
const _3ea758d8 = () => interopDefault(import('../pages/nueva-contrasena.vue' /* webpackChunkName: "pages/nueva-contrasena" */))
const _563b91cb = () => interopDefault(import('../pages/ofertas/index.vue' /* webpackChunkName: "pages/ofertas/index" */))
const _487d6b76 = () => interopDefault(import('../pages/olvide-mi-contrasena.vue' /* webpackChunkName: "pages/olvide-mi-contrasena" */))
const _018e0fb8 = () => interopDefault(import('../pages/politicas-de-privacidad.vue' /* webpackChunkName: "pages/politicas-de-privacidad" */))
const _7c59e468 = () => interopDefault(import('../views/Preferences.vue' /* webpackChunkName: "" */))
const _5f3e46c3 = () => interopDefault(import('../pages/prensa.vue' /* webpackChunkName: "pages/prensa" */))
const _6c67e3bd = () => interopDefault(import('../pages/registro.vue' /* webpackChunkName: "pages/registro" */))
const _06a1de56 = () => interopDefault(import('../pages/reglas-de-comunidad.vue' /* webpackChunkName: "pages/reglas-de-comunidad" */))
const _646b699c = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _76254b44 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _10582261 = () => interopDefault(import('../pages/sitemap/index/index.vue' /* webpackChunkName: "pages/sitemap/index/index" */))
const _03e8c7d8 = () => interopDefault(import('../pages/sitemap/index/promociones/index.vue' /* webpackChunkName: "pages/sitemap/index/promociones/index" */))
const _f95eb1ec = () => interopDefault(import('../pages/sitemap/index/tiendas/index.vue' /* webpackChunkName: "pages/sitemap/index/tiendas/index" */))
const _07560a68 = () => interopDefault(import('../pages/sitemap/index/promociones/_slug.vue' /* webpackChunkName: "pages/sitemap/index/promociones/_slug" */))
const _4f06bca4 = () => interopDefault(import('../pages/sobre-nos.vue' /* webpackChunkName: "pages/sobre-nos" */))
const _292ed6b0 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _d6996592 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _3b3c78d8 = () => interopDefault(import('../pages/verificar-cuenta.vue' /* webpackChunkName: "pages/verificar-cuenta" */))
const _6cfa0394 = () => interopDefault(import('../pages/compartir/chat.vue' /* webpackChunkName: "pages/compartir/chat" */))
const _6c70f554 = () => interopDefault(import('../pages/compartir/cupon/index.vue' /* webpackChunkName: "pages/compartir/cupon/index" */))
const _0362c0e1 = () => interopDefault(import('../pages/compartir/finalizado.vue' /* webpackChunkName: "pages/compartir/finalizado" */))
const _49f0d1b0 = () => interopDefault(import('../pages/compartir/oferta/index.vue' /* webpackChunkName: "pages/compartir/oferta/index" */))
const _7fcd4aee = () => interopDefault(import('../pages/compartir/cupon/crear.vue' /* webpackChunkName: "pages/compartir/cupon/crear" */))
const _c4cd9236 = () => interopDefault(import('../pages/compartir/oferta/crear.vue' /* webpackChunkName: "pages/compartir/oferta/crear" */))
const _8de49746 = () => interopDefault(import('../views/StoreMask.vue' /* webpackChunkName: "" */))
const _fcba3a86 = () => interopDefault(import('../pages/blog/_category/index.vue' /* webpackChunkName: "pages/blog/_category/index" */))
const _a9b1b29e = () => interopDefault(import('../views/DiscountDetails.vue' /* webpackChunkName: "" */))
const _5f8a3c56 = () => interopDefault(import('../pages/dashboard/_user.vue' /* webpackChunkName: "pages/dashboard/_user" */))
const _a3ac16d0 = () => interopDefault(import('../pages/dashboard/_user/index.vue' /* webpackChunkName: "pages/dashboard/_user/index" */))
const _2d388783 = () => interopDefault(import('../pages/dashboard/_user/actividad.vue' /* webpackChunkName: "pages/dashboard/_user/actividad" */))
const _3fe7d761 = () => interopDefault(import('../pages/dashboard/_user/chats.vue' /* webpackChunkName: "pages/dashboard/_user/chats" */))
const _4461e635 = () => interopDefault(import('../pages/dashboard/_user/descuentos.vue' /* webpackChunkName: "pages/dashboard/_user/descuentos" */))
const _2b40071f = () => interopDefault(import('../pages/eventos/_slug.vue' /* webpackChunkName: "pages/eventos/_slug" */))
const _287da364 = () => interopDefault(import('../pages/foros/_slug.vue' /* webpackChunkName: "pages/foros/_slug" */))
const _4003b80d = () => interopDefault(import('../views/DiscountMask.vue' /* webpackChunkName: "" */))
const _39499526 = () => interopDefault(import('../pages/novedades/_slug.vue' /* webpackChunkName: "pages/novedades/_slug" */))
const _35cf24c8 = () => interopDefault(import('../pages/blog/_category/_article.vue' /* webpackChunkName: "pages/blog/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aviso-legal",
    component: _f344487c,
    name: "aviso-legal"
  }, {
    path: "/ayuda",
    component: _5e35a4e0,
    name: "ayuda"
  }, {
    path: "/blog",
    component: _b9161ba2,
    name: "blog"
  }, {
    path: "/cambiar-contrasena",
    component: _14a9b214,
    name: "cambiar-contrasena"
  }, {
    path: "/categorias",
    component: _76a69105,
    name: "categorias"
  }, {
    path: "/comentados",
    component: _4c5f08a4,
    name: "index-comentados"
  }, {
    path: "/compartir",
    component: _b797a574,
    name: "compartir"
  }, {
    path: "/contacto",
    component: _092ba5d9,
    name: "contacto"
  }, {
    path: "/contrasena-actualizada-exitosamente",
    component: _2a9a3d83,
    name: "contrasena-actualizada-exitosamente"
  }, {
    path: "/cookies",
    component: _aecaa616,
    name: "cookies"
  }, {
    path: "/cupones",
    component: _f74c6798,
    name: "cupones"
  }, {
    path: "/dashboard",
    component: _aad3dc06,
    children: [{
      path: "",
      component: _f14b3280,
      name: "dashboard-index"
    }, {
      path: "actividad",
      component: _3713a5ab,
      name: "dashboard-index-actividad"
    }, {
      path: "ajustes",
      component: _9a38e3ae,
      name: "dashboard-index-ajustes"
    }, {
      path: "chats",
      component: _19184989,
      name: "dashboard-index-chats"
    }, {
      path: "descuentos",
      component: _75ea8d0d,
      name: "dashboard-index-descuentos"
    }, {
      path: "guardados",
      component: _4f1d275a,
      name: "dashboard-index-guardados"
    }, {
      path: "monedas",
      component: _61a4910b,
      name: "dashboard-index-monedas"
    }, {
      path: "premios",
      component: _26337d91,
      name: "dashboard-index-premios"
    }]
  }, {
    path: "/eventos",
    component: _2cf6a867,
    name: "eventos"
  }, {
    path: "/expirados",
    component: _b17f239a,
    name: "expirados"
  }, {
    path: "/faq",
    component: _338130fc,
    name: "faq"
  }, {
    path: "/foros",
    component: _251060d4,
    name: "foros"
  }, {
    path: "/gratis",
    component: _56772d92,
    name: "gratis"
  }, {
    path: "/inicio-sin-contrasena",
    component: _59fe9f6e,
    name: "inicio-sin-contrasena"
  }, {
    path: "/login",
    component: _59fa4f62,
    name: "login"
  }, {
    path: "/lp",
    component: _ff4a64b8,
    name: "lp"
  }, {
    path: "/marcas",
    component: _10d63f1b,
    name: "marcas"
  }, {
    path: "/mensajeria",
    component: _432a7398,
    name: "mensajeria"
  }, {
    path: "/notificaciones",
    component: _481f5258,
    name: "notificaciones"
  }, {
    path: "/novedades",
    component: _3b00366e,
    name: "novedades"
  }, {
    path: "/nueva-contrasena",
    component: _3ea758d8,
    name: "nueva-contrasena"
  }, {
    path: "/nuevos",
    component: _4c5f08a4,
    name: "index-nuevos"
  }, {
    path: "/ofertas",
    component: _563b91cb,
    name: "ofertas"
  }, {
    path: "/olvide-mi-contrasena",
    component: _487d6b76,
    name: "olvide-mi-contrasena"
  }, {
    path: "/politicas-de-privacidad",
    component: _018e0fb8,
    name: "politicas-de-privacidad"
  }, {
    path: "/preferencias",
    component: _7c59e468,
    name: "preferences"
  }, {
    path: "/prensa",
    component: _5f3e46c3,
    name: "prensa"
  }, {
    path: "/registro",
    component: _6c67e3bd,
    name: "registro"
  }, {
    path: "/reglas-de-comunidad",
    component: _06a1de56,
    name: "reglas-de-comunidad"
  }, {
    path: "/search",
    component: _646b699c,
    name: "search"
  }, {
    path: "/sitemap",
    component: _76254b44,
    children: [{
      path: "",
      component: _10582261,
      name: "sitemap-index"
    }, {
      path: "promociones",
      component: _03e8c7d8,
      name: "sitemap-index-promociones"
    }, {
      path: "tiendas",
      component: _f95eb1ec,
      name: "sitemap-index-tiendas"
    }, {
      path: "promociones/:slug?",
      component: _07560a68,
      name: "sitemap-index-promociones-slug"
    }]
  }, {
    path: "/sobre-nos",
    component: _4f06bca4,
    name: "sobre-nos"
  }, {
    path: "/subscribe",
    component: _292ed6b0,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _d6996592,
    name: "unsubscribe"
  }, {
    path: "/verificar-cuenta",
    component: _3b3c78d8,
    name: "verificar-cuenta"
  }, {
    path: "/compartir/chat",
    component: _6cfa0394,
    name: "compartir-chat"
  }, {
    path: "/compartir/cupon",
    component: _6c70f554,
    name: "compartir-cupon"
  }, {
    path: "/compartir/finalizado",
    component: _0362c0e1,
    name: "compartir-finalizado"
  }, {
    path: "/compartir/oferta",
    component: _49f0d1b0,
    name: "compartir-oferta"
  }, {
    path: "/compartir/cupon/crear",
    component: _7fcd4aee,
    name: "compartir-cupon-crear"
  }, {
    path: "/compartir/oferta/crear",
    component: _c4cd9236,
    name: "compartir-oferta-crear"
  }, {
    path: "/go/marcas/:id",
    component: _8de49746,
    name: "go-store-slug"
  }, {
    path: "/blog/:category",
    component: _fcba3a86,
    name: "blog-category"
  }, {
    path: "/categorias/:slug",
    component: _76a69105,
    name: "categorias-slug"
  }, {
    path: "/cupones/:slug",
    component: _a9b1b29e,
    name: "cupones-slug"
  }, {
    path: "/dashboard/:user",
    component: _5f8a3c56,
    children: [{
      path: "",
      component: _a3ac16d0,
      name: "dashboard-user"
    }, {
      path: "actividad",
      component: _2d388783,
      name: "dashboard-user-actividad"
    }, {
      path: "chats",
      component: _3fe7d761,
      name: "dashboard-user-chats"
    }, {
      path: "descuentos",
      component: _4461e635,
      name: "dashboard-user-descuentos"
    }]
  }, {
    path: "/eventos/:slug",
    component: _2b40071f,
    name: "eventos-slug"
  }, {
    path: "/foros/:slug",
    component: _287da364,
    name: "foros-slug"
  }, {
    path: "/go/:id",
    component: _4003b80d,
    name: "go-id"
  }, {
    path: "/goprize/:hash",
    component: _4003b80d,
    name: "goprize-hash"
  }, {
    path: "/lp/:slug",
    component: _ff4a64b8,
    name: "lp-slug"
  }, {
    path: "/marcas/:slug",
    component: _10d63f1b,
    name: "marcas-slug"
  }, {
    path: "/novedades/:slug",
    component: _39499526,
    name: "novedades-slug"
  }, {
    path: "/ofertas/:slug",
    component: _a9b1b29e,
    name: "ofertas-slug"
  }, {
    path: "/open/:slug",
    component: _4003b80d,
    name: "open-slug"
  }, {
    path: "/blog/:category/:article",
    component: _35cf24c8,
    name: "blog-category-article"
  }, {
    path: "/",
    component: _4c5f08a4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
