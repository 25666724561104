export default {
    brand: 'OKdescontos',
    symbol: 'R$',
    routes: {
        subscribe: 'assinar',
        unsubscribe: 'cancelar-assinatura',
        activity: 'atividade',
        awards: 'premios',
        blog: 'blog',
        brands: 'lojas',
        categories: 'promocoes',
        chat: 'chat',
        chats: 'chats',
        commented: 'comentou',
        coupon: 'cupom',
        coupons: 'cupons',
        discounts: 'descuentos',
        events: 'eventos',
        changePassword: 'alterar-minha-senha',
        forgotPassword: 'esqueci-minha-senha',
        successChangedPassword: 'senha-atualizada-com-sucesso',
        forum: 'forum',
        free: 'gratis',
        news: 'noticias',
        novelties: 'novidades',
        offer: 'oferta',
        offers: 'ofertas',
        rewards: 'premios',
        share: 'postar',
        contact: 'contato',
        expired: 'expirado',
        extension: 'extensao',
        extensionUninstalled: 'extensao-desinstalada',
        ended: 'finalizado',
        verifyAccount: 'verificar-conta',
        search: 'search',
        newPassword: 'nova-senha',
        loginWithoutPass: 'login-sem-senha',
        create: 'criar',
        dashboard: 'dashboard',
        notifications: 'notificacoes',
        inbox: 'mensagens',
        saved: 'ofertas-que-sigo',
        preferences: 'preferencias',
        settings: 'configuracoes',
        login: 'entrar',
        register: 'cadastrar',
        recovery: 'recuperar-senha',
        coins: 'moedas-ganhas',
        rules: 'normas-comunitarias',
        help: 'ajuda',
        about_us: 'sobre-nos',
        terms: 'termos-de-uso',
        privacy: 'politica-de-privacidade',
        cookies: 'cookies',
        legal: 'termos-de-uso',
        press: 'imprensa',
        faq: 'faq',
    },
    components: {
        CardSliderEventFeaturedDiscounts: {
            discount_not_found: 'descontos não encontrados',
        },
        ctaSocial: {
            notification: 'Notificações',
            notification_text: 'Gostaria de receber alertas GRATUITAS sobre as melhores ofertas através de',
            notification_text_2: {
                facebook: 'Facebook?',
                telegram: 'Telegram?',
                whatsapp: 'Whatsapp?',
            },
            team: 'Equipe OKdescontos',
            accept: 'Sim, aceito!',
            url: {
                telegram: 'https://t.me/okdescontos',
                facebook: 'https://www.facebook.com/groups/710058077386583',
                whatsapp: 'https://chat.whatsapp.com/DT4LHVI9Ynn3dEKhsykavj',
            },
        },
        ctaExtAndApp: {
            url: 'https://chrome.google.com/webstore/detail/okdescontos/gkochegofmljpmgegphbcljdigenjlhd?hl=es&authuser=5',
            extHead: 'Instalar gratuitamente nossa',
            extTitle: 'extensão',
            extDescription: 'Motor de busca automático de cupões e ofertas',
            appHead: 'Baixar',
            appTitle: 'nossa aplicação',
        },
        ctaEMail: {
            accept_1: 'Sim, aceito a',
            accept_2: 'Política de Privacidade',
            accept_3: 'e os',
            accept_4: 'Termos e Condições',
            accept_5: 'da OKdescontos',
            title: 'Receba todos os dias os',
            title_2: 'melhores descontos',
            title_3: 'no seu e-mail',
            subtitle: 'Todos os dias novas ofertas e cupões',
            subtitle_2: '100% gratuito!',
            placeholder: 'Introduza o seu endereço de correio electrónico aqui..',
            button: 'Registar-se',
            success: 'E-mail correctamente subscrito.',
            failed: 'O correio electrónico já está subscrito.',
            error: 'Ocorreu um erro',
            insertEmail: 'Introduzir um endereço de correio electrónico',
        },
        bannerHomepage: {
            welcome: 'Bem-vindo à OKdescontos, esta é a sua casa!',
            signup: 'Compartilhe, avalie e comente as promoções e cupons',
        },
        sidebarUserRanking: {
            rank: 'Classificação',
            weekly: 'Semanalmente',
            monthly: 'Por mês',
            comments: 'Comentários',
            discounts: 'Descontos',
        },
        microArcLinks: {
            discounts: 'Descontos',
        },
        sidebarBanners: {
            publicity: 'Publicidade',
        },
        cardMainGlobalFeatured: {
            published_by: 'Por',
            ago: 'Há',
            offer_big: 'OFERTA',
            coupon_big: 'CUPOM',
            more: 'Mais de',
            okay: 'De acordo',
            free_ship: 'Envio Grátis',
            free: 'GRÁTIS',
            offers: 'ofertas',
            coupons: 'cupons',
            offer: 'Oferta',
            coupon: 'Cupom',
            save: 'Salvar',
            delete: 'remover',
            saved: 'salvou',
            saved_a: 'salvou',
            deleted: 'removido',
            deleted_a: 'deletado',
            something_happens: 'algo está errado com',
            error_on: 'Erro quando',
            this_offer: 'esta oferta',
            this_coupon: 'este cupom',
            error_on_save: 'Erro ao salvar, verifique o status da sua sessão',
        },
        bannerGeneric: {
            signup_google: 'Iniciar com o Google',
            signup_facebook: 'Iniciar com Facebook',
            follow: 'Seguir',
            earn_prizes: 'Ganhe prêmios e brindes participando!',
            register_earn_coins: 'Registre-se gratuitamente hoje e ganhe moedas',
            register_earn_md: 'Registre-se gratuitamente hoje e ganhe mega descontos',
            follow_us: 'Siga-nos para saber mais sobre ofertas, promoções e descontos',
            main_image_alt: 'ícone',
            coin_image_alt: 'ícone',
        },
        btnDiscount: {
            copied: 'Copiado',
            see_coupon: 'Ver cupom',
            see_offer: 'Ver oferta',
            no_coupon_required: 'NÃO NECESSITA DE CUPOM',
        },
        btnSeeMoreResults: {
            see_more: 'Ver mais',
        },
        cardDashboardActivity: {
            time_ago: 'Desde',
            see_forum: 'Ver bate-papo/foro',
            see_offer: 'Ver oferta',
            see_coupon: 'Ver cupom',
            unsupported: 'sem suporte',
        },
        cardDashboardCoin: {
            coins: 'Moedas',
            see_coins: 'Ver moedas',
            swap: 'Resgatar',
        },
        cardDashboardRouter: {
            end_at: 'Termina em {{data|time}}',
            back: 'Atrás',
        },
        cardMainCoupons: {
            coupon: 'CUPOM',
            verified: 'Verificado',
            exclusive: 'Exclusivo',
            today: 'HOJE',
            tomorrow: 'AMANHÃ',
            updated_at: 'Atualizado há',
            free: 'Grátis',
            free_ship: 'Envio Grátis',
            time_ago: 'Desde',
            end_at: 'Termina',
            local: 'Local',
            hide: 'Ocultar',
            show: 'Mostrar',
        },
        layoutFooter: {
            register_free: 'Cadastre-se gratuitamente e receba descontos em seu e-mail',
            write_email: 'Escreva seu e-mail aqui...',
            subscribe: 'inscrever-se',
            community: 'A comunidade',
            first_of: '#1 de descontos no Brasil.',
            enjoy: 'Aproveite cupons, ofertas e promoções 100% grátis. Inscreva-se gratuitamente e ganhe moedas pela sua atividade. A melhor plataforma para economizar se chama OKdescontos.',
            privacy_policy: 'Política de privacidade',
            terms_of_use: 'termos de uso',
            cookies_policy: 'Política de cookies',
            successSubscription: 'E-mail cadastrado com sucesso',
            emailAlreadyExist: 'O mail já está subscrito',
            errorOnSibscribing: 'Um erro ocorreu',
        },
        cardMainGlobal: {
            programmed: 'Programado',
            verified: 'Verificado',
            exclusive: 'Exclusivo',
            today: 'HOJE',
            tomorrow: 'AMANHÃ',
            updated_at: 'Última atualização',
            time_ago: 'Há',
            end_at: 'Termina em',
            local: 'Local',
            new: 'Novo',
            see_more: 'Ver mais',
            start_at: 'Inicia',
            agreement: 'De acordo',
            free: 'Grátis',
            free_ship: 'FRETE GRÁTIS',
            offers: 'ofertas',
            coupons: 'cupons',
            offer: 'Oferta',
            coupon: 'Cupom',
            save: 'salvar',
            delete: 'eliminar',
            saved: 'guardado',
            saved_a: 'guardada',
            deleted: 'eliminado',
            deleted_a: 'eliminada',
            something_happens: 'Erro ao excluir',
            error_on: 'erro ao',
            this_offer: 'esta oferta',
            this_coupon: 'este cupom',
            error_on_save: 'Erro ao salvar, verifique o status da sua sessão',
        },
        cardMainGlobalSkeleton: {
            new: 'Novo',
            see_more: 'Ver mais',
            offers: 'ofertas',
        },
        cardMainMini: {
            free: 'GRÁTIS',
            free_ship: 'FRETE GRÁTIS',
        },
        cardSharePreviewChat: {
            title: 'Este é o título do chat',
            select: 'Selecionar...',
            select_category: 'Seleciona uma Categoria',
            description: 'Aqui vai a descrição',
        },
        cardSharePreviewOfferAndCoupon: {
            title: 'Aqui vai o título do seu',
            new: 'Novo',
            description1: 'Aqui está a descrição do seu',
            description2: 'e vai se completando aos poucos.',
            information:
                'Informações do sorteio como datas, número de vezes que você pode participar e todos os detalhes…',
            start_at: 'Inicia',
            end_at: 'Finaliza',
            free: 'GRÁTIS',
            free_ship: 'Frete grátis',
        },
        layoutHeaderSearch: {
            error: 'erro de procura',
            search: 'Procurar',
            must_min_2_chars: 'Você deve inserir pelo menos 2 caracteres para uma pesquisa mais exata.',
            okay: 'De acordo',
        },
        microStepper: {
            share: 'Compartilhar',
            description: 'Descrição',
            finished: '¡Finalizado!',
        },
        cardShareStepOne: {
            share: 'Compartilhar',
            paste_link: 'Cole o link onde você pode usar o código ou veja mais informações.',
            coupon_code: 'Código do cupom',
            no_link_needed: 'Não precisa de link',
            start: 'Vamos começar!',
            share_offer: 'Compartilhar oferta',
            share_coupon: 'Compartilhar cupom',
            insert_valid_url: 'Insira um URL válido ou clique no botão "Nenhum link necessário"',
            hide: 'Ocultar',
            image_not_found: 'Não encontramos nenhuma imagem no link, mas você pode adicioná-las manualmente',
            error: 'Ocorreu um erro inesperado, tente novamente em alguns minutos',
        },
        cardShareStepTwo: {
            share: 'Compartilhar',
            share_coupon: 'Compartilhar cupom?',
            share_offer: 'Compartilhar oferta?',
            link: 'Link',
            required: 'Obrigatório',
            optional: 'Opcional',
            error: 'Há um erro. Por favor, verifique este campo.',
            images_from_link: 'Carregar imagem',
            upload_images_here: 'Carregar imagem aqui',
            offer_details: 'Detalhes da oferta (Opcional)',
            error_field: 'Há um erro. Por favor, verifique este campo.',
            discount_type: 'Tipo de desconto',
            coupon_code: 'Código do Cupom',
            coupon_to_use: 'Cupom para usar (somente se necessário)',
            title: 'Título',
            set_offer_details: 'Insira os detalhes da oferta (loja, desconto e o que você quiser)',
            max_140_chars: 'Máximo de 140 caracteres',
            description: 'Descrição',
            category: 'Categoria',
            select_category: 'Selecione a categoria que melhor se adapta à sua oferta',
            where_participate: 'Onde participar?',
            error_fields: 'Há um erro. Por favor, verifique os campos obrigatórios.',
            start_date: 'Data de início',
            end_date: 'Data de expiração',
            where_send: 'De onde é enviado?',
            local_coupon: 'É um cupom local?',
            local_offer: 'É uma oferta local?',
            send: 'Enviar',
            preview: 'Visualizaçao prévia',
            select: 'Seleciona...',
            website: 'Website',
            other: 'Outro',
            description_long:
                'Sua descrição vai aqui, adicione todas as informações possíveis, como condições, locais, etc.',
            insert_valid_url: 'Por favor, insira um URL válido',
            hide: 'Ocultar',
            images_not_found:
                'Não encontramos nenhuma imagem no link, mas você pode adicioná-las manualmente',
            title_40_chars: 'O título deve ter mais de 40 caracteres',
            default_image_no_removable:
                'Você não pode excluir a imagem padrão, mas pode fazer upload ou selecionar outra',
            file_not_permitted: 'Arquivo não permitido',
            size_exceeded: 'Arquivo superdimensionado',
        },
        CardSwiperEventFeaturedDiscounts: {
            discount_not_found: 'Não encontramos nenhum desconto pendente para este evento',
            featured_discounts: 'Descontos em destaque',
        },
        CardSwiperEventFeaturedStores: {
            store_not_found: 'Não encontramos nenhuma loja em destaque para este evento.',
            featured_stores: 'Lojas em destaque',
            today: 'Hoje',
            day: 'Dia',
            week: 'Semana',
            month: 'Mês',
            always: 'Sempre',
        },
        cardSwiperFeaturedDiscounts: {
            featured_offers: 'Ofertas destacadas',
            today: 'Hoje',
            day: 'Dia',
            week: 'Semana',
            month: 'Mês',
            always: 'Sempre',
        },
        cardBlog: {
            minutes: 'Minutos',
            like: 'Likes',
        },
        cardComment: {
            like: 'Likes',
            answer: 'Responder',
            responses: 'respostas',
            copy_link_to_comment: 'Copiar link para comentar',
            report: 'Denunciar',
            why_report: 'Por que você denuncia?',
            spam_offensive: 'Spam/Ofensivo',
            abuse_hate: 'Assédio ou discurso de ódio',
            okay: 'De acordo',
            report_success: 'Seu relatório foi enviado com sucesso',
            report_failed: 'Não foi possível denunciar',
            answer_published: 'Resposta postada com sucesso',
            answer_pending: 'Resposta pendente de aprovação',
            close: 'Fechar',
            hide: 'Ocultar',
        },
        cardCommentThread: {
            load_more_answers: 'Carregar respostas anteriores',
        },
        cardEvent: {
            starts: 'Inicia:',
            ends: 'Termina:',
            ended_at: 'Termina em',
        },
        cardExtension: {
            now_easier: 'Agora ficou mais fácil!',
            install_our_extension: 'Instale nossa extensão',
            go_to_extension: 'Vá para a extensão',
        },
        cardFaq: {
            frequent_questions: 'Perguntas frequentes',
            question: 'Perguntas',
        },
        cardFeatured: {
            coupon: 'CUPOM',
            offer: 'OFERTA',
            free: 'GRÁTIS',
            free_ship: 'Envio Grátis',
        },
        cardFeaturedShop: {
            coupon: 'CUPOM',
            offer: 'OFERTA',
            free: 'GRÁTIS',
            free_ship: 'Envio Grátis',
        },
        cardNews: {
            created_at: 'Foi criado o',
        },
        cardNotifications: {
            see_novelty: 'Ver novidade',
            see_novelties: 'Ver novidades',
            see_comment: 'Ver comentário',
            see_publication: 'Ver publicação',
            see_chat: 'Ver bate-papo',
            see_offer: 'Ver oferta',
            see_coupon: 'Ver cupom',
            see_message: 'Ver mensagem',
            see_coins: 'Ver moedas',
            see_forum: 'Ver fórum',
            remove: 'Apagar',
            delete_notification: 'Excluir esta notificação',
            want_delete_notification: 'Deseja remover esta notificação?',
            delete: 'Eliminar',
            deleted_notification: 'notificação removida',
            error_delete_notification: 'Ocorreu um erro ao remover a notificação',
            hide: 'Ocultar',
            cancel: 'Cancelar',
            deactivate: 'Desativar',
            confirm: 'Confirme',
            stop_receiving_notifications_like_this: 'Pare de receber notificações como esta',
            want_stop_receiving_notifications_like_this: 'Deseja parar de receber notificações como esta?',
            silenced_notification: 'otificação silenciada',
            error_silence_notification: 'Ocorreu um erro ao silenciar a notificação',
        },
        cardPostUserInfo: {
            created_at: 'Foi criado o',
            reading_time: 'Tempo de leitura:',
            minutes: 'minutos',
            last_update: 'Última atualização',
            at: 'a las',
        },
        chipGeneric: {
            posts: 'Postagens',
        },
        dropdownConfirmAction: {
            yes: 'Sim',
            no: 'Não',
            hide: 'Ocultar',
            expired_success: 'Expirou com sucesso',
        },
        formFilterByLetter: {
            see_more: 'Ver mais',
        },
        formFieldAvatarSelect: {
            having_profile_identifies: 'Ter uma foto de perfil ajuda a ser identificado.',
            photo: 'foto',
        },
        formFieldGiphy: {
            search_gif: 'Pesquisar gif',
        },
        formFieldPrice: {
            offer_price: 'Preço en oferta',
            regular_price: 'Preço regular',
            ship_price: 'Custo de envio',
            optional: 'Opcional',
            free_promotion: 'Verifique se a promoção é gratuita',
            free_ship: 'Envio Grátis',
            price_offer_higher_than_regular: 'O preço de venda não deve ser superior ao preço normal',
        },
        formFieldTabs: {
            percent: 'Porcentagem',
            pesos: 'Reais',
            free_ship: 'Envio Grátis',
            discount: 'de desconto',
            required: 'Obrigatório',
            required_discount: 'O tipo de desconto é obrigatório',
        },
        formEmailLoginStrategy: {
            email_sended: 'Enviamos um e-mail de 6 dígitos para recuperar sua senha',
            check_email: 'Verifique a sua caixa de entrada e insira o código enviado nas caixas',
            hide: 'Ocultar',
            welcome: 'Bem-vindo a OKdescontos',
        },
        formForgotPassword: {
            noproblem_go_back: 'Sem problemas, volte',
            goback: 'Voltar',
            have_troubles: 'Algum problema?',
            contact_us: 'Contate-nos',
            change_password: 'Mudar a senha',
            check_email: 'Digite o seu e-mail e enviaremos um código de 6 dígitos para redefinir a sua senha',
            set_email: 'Digite o seu e-mail',
            email: 'E-mail',
            login_without_password: 'Iniciar sessão sem senha',
            restore_password: 'Restaurar senha',
        },
        formLogin: {
            email_or_username: 'E-mail ou nome de usuário',
            password: 'Senha',
            login: 'Entrar',
            forgot_password: 'Esqueceu a senha?',
            remember_login: 'Lembrar-me',
            login_google: 'Entrar com Google',
            login_facebook: 'Entrar com Facebook',
            hide: 'Ocultar',
            welcome: 'Bem-vindo a OKdescontos',
        },
        formMakeComment: {
            answer: 'Responder',
        },
        formQuill: {
            add_comment: 'Adicione um comentário',
            accept_terms: 'Para poder editar você deve aceitar os termos e condições do site',
            verify_account: 'Para fazer um comentário, você precisa verificar sua conta',
        },
        formRegister: {
            username: 'Nome de usuário',
            email: 'E-mail',
            password: 'Senha',
            register: 'Registra-se',
            politics_accepted: 'Ao continuar, concordo com a',
            politics_accepted2: 'e os',
            politics_accepted3: ' de OKdescontos.',
            privacy_policy: 'Política de Privacidade',
            terms_of_use: 'Termos de Uso',
            newsletter: 'Receba o boletim com promoções diárias',
            signup_google: 'Entrar com Google',
            signup_facebook: 'Entrar com Facebook',
            need_accept_terms: 'Você deve concordar com os termos e condições',
        },
        layoutHeaderLinksWarning: {
            monetized: 'Alguns links em nosso site são monetizados. Isso nos ajuda a manter a plataforma',
        },
        layoutHeaderMenu: {
            menu: 'Menu',
            home: 'Início',
            coupons: 'Cupons',
            offers: 'Ofertas',
            free: 'Grátis',
            categories: 'Categorias',
            brands: 'Lojas',
            chats: 'Bate-Papo',
            novelties: 'Novidades',
            blog: 'Blog',
        },
        layoutHeaderSuggestions: {
            stores: 'Lojas',
            discount: 'desconto',
            discounts: 'descontos',
            categories: 'Categorias',
            events: 'Eventos',
            no_results: 'Não há resultados',
        },
        layoutHeaderUser: {
            join_free: 'Junte-se',
            notifications_panel: 'Painel de notificações',
            messages_panel: 'Painel de mensagens',
            share: 'Compartilhar',
            dashboard: 'Painel de controle',
            notifications: 'Notificações',
            messages: 'Mensagens',
            saved: 'Salvos',
            coins: 'Moedas',
            rewards: 'Prêmios',
            settings: 'Configurações',
            close_session: 'Encerrar Sessão',
            in_close_session: 'Você está logado',
            session_closed: 'Sessão fechada forçadamente',
            share_discounts: 'Compartilhar oferta/cupom/sorteio/chat',
        },
        layoutInfoPages: {
            index: 'Índice',
        },
        listThreads: {
            index: 'Índice',
            author: 'Autor',
            title: 'Título',
            thread: 'Tópico',
            date: 'Data',
            all: 'Tudo',
            results_not_found: 'Nenhum resultado encontrado',
        },
        microDiscountFooter: {
            related_categories: 'Categorias relacionadas',
            see_more_categories: 'Ver mais categorias',
            related_stores: 'Lojas relacionadas',
            popular_stores: 'Lojas populares',
            see_more_stores: 'Ver mais lojas',
        },
        microHeatCounterVote: {
            expired: 'EXPIRADO',
        },
        microTabs: {
            order_by: 'Ordenar por',
        },
        modalAuthAcceptTerms: {
            order_by: 'Ordenar por',
            privacy: 'Privacidade',
            accept_terms: 'Aceitar termos e condições',
            accept_terms_message:
                'Para finalizar o cadastro, aceite a Política de Privacidade e os Termos de Uso da Okdescontos.',
            continue_accept_terms: 'Ao continuar, concordo com a',
            privacy_policy: 'Política de Privacidade',
            continue_accept_terms2: 'e',
            terms_of_use: 'Termos de Uso',
            continue_accept_terms3: 'da OKdescontos.',
            okneed_accept_terms: 'Você deve aceitar os termos de uso',
            continue: 'Continuar',
            hide: 'Ocultar',
            can_interact: 'Agora você pode interagir em nossa comunidade',
            login_to_verify: 'Faça login para verificar sua conta',
            not_valid: 'Sua conta pode não ser válida, entre em contato com o suporte',
        },
        modalAuthEmailVerification: {
            verify_email: 'Verificar e-mail',
            registered_success:
                'Você se registrou com sucesso na comunidade da OKdescontos, nós lhe enviamos um e-mail de verificação',
            check_spam:
                'Revise a pasta de spam para verificar sua conta e selecione se é um remetente seguro',
            email_sended: 'E-mail enviado',
            email_resend: 'Reenviar e-mail',
            email_verify: 'Enviamos um e-mail de verificação para o endereço de e-mail que você registrou',
            email_verify_error:
                'Ocorreu um erro ao enviar o e-mail de verificação. Tente novamente em alguns minutos',
            continue: 'Continuar',
        },
        modalAuthLogin: {
            login_or_register: 'Iniciar sessão ou registrar-se',
            join_adventages: 'Vantagens em unir-se',
            create_account: 'Criar Conta',
            login_with_social: 'Iniciar sessão com Rede Social',
            login_with_email: 'Iniciar com e-mail',
            email_or_username: 'e-mail ou nome de usuário',
            password: 'senha',
            forgot_password: 'Esqueceu a senha?',
            remember_login: 'Lembrar início de sessão',
            login: 'Entrar',
            comment_vote_offers: 'Comente, vote e envie ofertas',
            participate: 'Participe e ganhe vales-presente da Amazon e muito mais',
            joinus: 'Junte-se à melhor comunidade de ofertas no Brasil',
            login_google: 'Fazer login com Google',
            login_facebook: 'Fazer login com Facebook',
            hide: 'Ocultar',
            welcome: 'Bem-vindo a OKdescontos',
            invalid_credentials: 'Credenciais inválidas',
        },
        modalAuthRegister: {
            register: 'Registro',
            welcome: 'Bem-vindo a OKdescontos, registre-se no Facebook, Google ou com seu e-mail',
            have_account: 'Você já tem uma conta?',
            register_email: 'Inscreva-se com seu e-mail',
            email: 'e-mail',
            username: 'Nome de usuário',
            password: 'Senha',
            continue_accept_terms: 'Ao continuar, concordo com as',
            community_rules: 'Regras da Comunidade',
            continue_accept_terms2: 'e os',
            terms_of_use: 'Termos de Uso',
            continue_accept_terms3: 'da OKdescontos.',
            must_accept_terms: 'Você deve concordar com os termos e condições',
            newsletter: 'Receba a newsletter com ofertas diárias',
            register_account: 'Registrar conta',
            register_google: 'Inscreva-se no Google',
            register_facebook: 'Inscreva-se com o Facebook',
            email_confirmation: 'Confirme o seu email. Verifique a pasta de spam',
        },
        modalAuthResetPasswordInit: {
            reset_password: 'Redefinir Senha',
            ready: 'Tudo pronto',
            check_email: 'Verifique seu e-mail e siga as instruções',
        },
        modalCookiesAdjust: {
            back: 'Atrás',
            cookies_adjust: 'Definições de cookies',
            save_settings: 'Guardar Definições',
            read_politics: 'Leia mais sobre nossa Política de Privacidade e Política sobre Cookies',
            cookies_strict: 'Cookies estritamente necessários',
            cookies_personalization: 'Cookies de personalização',
            cookies_functional: 'Cookies funcionais',
            cookies_personalization2: 'Cookies de personalização',
            privacy_policy: 'Política de Privacidade',
            cookies_policy: 'Política sobre Cookies',
            and: 'e',
            hide: 'Ocultar',
            cookies_saved: 'Configuração de cookies concluída com sucesso',
        },
        modalCookiesCookies: {
            cookies_image: 'Imagem de cookies',
            cookies_msg: '¡Ei! Nós somos os cookies!',
            cookies_description1:
                'Usamos cookies e tecnologias semelhantes para administrar nosso site e analisar seu tráfego. Também os usamos, com o seu consentimento, para mostrar e medir o desempenho de nossos anúncios e nos ajudar a entender como você interage com o conteúdo e as funcionalidades do site. Isso significa que podemos continuar a melhorar e personalizar sua experiência usando nossa plataforma. Você pode ter certeza de que seus dados estão seguros conosco, pois não os vendemos a terceiros. Ao aceitar esta mensagem, você concorda com nossa',
            cookies_description2: 'e nossa',
            cookies_description3: '(a partir da qual você pode atualizar suas preferências posteriormente).',
            privacy_policy: 'Política de Privacidad',
            cookies_policy: 'Política sobre Cookies',
            accept_all: 'Aceitar tudo',
            cookies_adjust: 'Definições de cookies',
            continue: 'Continuar sem aceitar',
            hide: 'Ocultar',
            cookies_saved: 'Configuração de cookies concluída com sucesso',
        },
        modalDashboardEmail: {
            change_email: 'Altere seu e-mail',
            write_new_email: 'Insira um novo e-mail para sua conta',
            email_15_days: 'O e-mail só pode ser atualizado a cada 15 dias',
            write_email: 'Escreva seu e-mail',
            cancel: 'Cancelar',
            accept: 'Aceitar',
            profile_updated: 'Seu perfil foi atualizado com sucesso',
        },
        modalDashboardPassword: {
            update_password: 'Atualize sua senha',
            insert_new_password: 'Digite uma nova Senha',
            current_password: 'senha atual',
            write_current_password: 'Escreva a sua senha atual',
            new_password: 'Nova Senha',
            write_new_password: 'Escreva sua nova senha',
            repeat_new_password: 'Repita a nova senha',
            cancel: 'Cancelar',
            accept: 'Aceitar',
            profile_updated: 'Seu perfil foi atualizado com sucesso',
            password_updated: 'Sua senha foi atualizada com sucesso',
        },
        modalDashboardUsername: {
            change_username: 'Alterar o seu nome de usuário',
            insert_new_username: 'Digite um novo nome de usuário para sua conta',
            username_only_one_per_year: 'O nome de usuário só pode ser atualizado uma vez por ano',
            write_new_username: 'Escreva o seu nome de usuário',
            cancel: 'Cancelar',
            accept: 'Aceitar',
            no_blank_spaces: 'Não pode conter espaços em branco',
            profile_updated: 'Seu perfil foi atualizado com sucesso',
        },
        modalMessagesNewMessage: {
            new_message: 'Nova mensagem',
            to: 'Para',
            username: 'Nome de usuário',
            no_users_available: 'Nenhum usuário disponível',
            message: 'Escreva sua mensagem',
            cancel: 'Cancelar',
            send: 'Enviar',
        },
        /* modalCoupon: {
            copy_and_paste1: 'Copie o código e cole-o em',
            copy_and_paste2: 'para obter o seu desconto',
            copied: 'Copiado',
            copy_and_go: 'Utilize o Cupom',
            share: 'Compartilhar',
            thanks_report: 'Obrigado pelo seu relato.',
            coupon_required: 'NÃO NECESSITA DE CUPOM',
        }, */
        modalGeneric: {
            thanks_one: 'Obrigado pelo seu comentário, foi enviado com sucesso! ',
            thanks_two: '¡Explore as melhores promoçoes e cupons!',
        },
        modalImageZoom: {
            image: 'Imagem',
        },
        sidebarCategories: {
            categories: 'Categorias',
            see_more: 'Ver mais',
            no_categories: 'Não há categorias disponiveis',
        },
        sidebarFeaturedOffers: {
            featured_offers: 'promoçoes em destaque',
            see_more: 'Ver mais',
            get: 'Obter',
        },
        sidebarFeaturedCoupons: {
            featured_coupons: 'Cupons em destaque',
            see_more: 'Ver mais',
        },
        cardMainSidebarDiscount: {
            see_coupon: 'Ver cupom',
            free: 'GRÁTIS',
            free_ship: 'FRETE GRÁTIS',
        },
        sidebarGiveMe: {
            get: 'Encontre-me também em',
        },
        sidebarMobileCategory: {
            categories: 'Categorias',
        },
        sidebarNetworkPages: {
            socials: 'Redes sociais',
        },
        sidebarOfferSummary: {
            coupons_for_shop: 'Número de cupons da loja:',
            offers_for_shop: 'Número de promoções para a loja:',
            last_update: 'Última atualização:',
            last_week: 'Novos descontos na última passada:',
            all_discounts: 'Número total de descontos:',
        },
        sidebarPaymentMethods: {
            methods: 'Métodos de pagamento',
            accepted_methods: 'Métodos de pagamento aceitos',
        },
        sidebarShare: {
            share: 'Compartilhar',
            copy_link: 'Copiar link',
            copied: '¡Copiado!',
            email: 'E-mail',
        },
        sidebarSimilar: {
            see_more: 'Ver tudo',
        },
        cardSidebarSomeCategories: {
            similar_categories: 'Categorias Semelhantes',
            no_categories: 'Não há categorias semelhantes disponíveis',
        },
        sidebarStores: {
            see_more: 'Ver mais',
            popular_stores: 'Nenhuma loja popular disponível',
        },
        layoutError: {
            no_exist:
                'Desculpe, esta página não existe ou não está disponível, mas não se preocupe, pode ser um erro nosso também. Se você vir esta página muitas vezes, entre em contato conosco.',
            go_back: 'Voltar para a página inicial',
            go_home: 'Ir ao início',
            try_again:
                'Tente fazer login com o usuário correto, atualize a página e se ainda tiver problemas nos envie uma mensagem',
            send_message: 'Envie-nos uma mensagem.',
            title_seo: 'Erro 404 | Página não encontrada | OKdescontos',
            description_seo: 'Página de erro',
            an_error_occurred:
                'ocorreu um erro no servidor ou você pode estar tentando uma ação que não é permitida',
        },
    },
    lib: {
        variant_dict: {
            offer: {
                plural: 'ofertas',
                singular: 'oferta',
                singular_one: 'oferta',
                plain: 'oferta',
            },
            coupon: {
                plural: 'cupons',
                singular: 'cupom',
                singular_one: 'um cupom',
                plain: 'cupom',
            },
            chat: {
                plural: 'bate-papos',
                singular: 'bate-papo',
                singular_one: 'um bate-papo',
            },
        },
        date_functions: {
            months: [
                'janeiro',
                'fevereiro',
                'março',
                'abril',
                'maio',
                'junho',
                'julho',
                'agosto',
                'setembro',
                'outubro',
                'novembro',
                'dezembro',
            ],
            times: {
                sec: 'seg',
                min: 'min',
                hs: 'h',
                day: 'dia(s)',
                week: 'sem',
                month: 'mês(s)',
                year: 'ano(s)',
            },
            ends_today: 'Termina HOJE',
            ends_tomorrow: 'Termina AMANHÃ',
            ends_in: 'Termina em {{days}} dias',
            ends_in_week: 'Termina em 1 semana',
            ends_at: 'Termina em {{date}}',
            datetimeFormat: 'pt',
            timezone: 'America/Sao_Paulo',
        },
    },
    middleware: {
        ifAcceptTerms: {
            text: 'Para compartilhar uma promoção, cupom, sorteio ou bate-papo/fórum você deve aceitar os termos e condições do site',
        },
        ifAuthenticated: {
            text: 'Você não tem permissão para acessar esta página',
        },
    },
    plugins: {
        axiosInterceptors: {
            for: 'Para',
            need_accept_terms: 'você precisa aceitar os termos e condições',
            need_verify_account: 'você precisa verificar sua conta.',
            do_votes: 'dar votos',
            do_save_discounts: 'salvar descontos',
            do_mark_expired: 'marcar como expirado',
            do_report: 'denunciar',
            do_likes: 'dar curtidas',
            do_comment: 'fazer um comentário',
            do_create_forum: 'criar um fórum/bate-papo',
            do_create_coupon: 'criar um cupom',
            do_create_offer: 'criar uma promoção',
            no_do_action: 'Sua conta não permite esta ação',
            no_permission: 'Você não tem permissão para acessar esta página',
        },
    },
    views: {
        categoriesBridge: {
            not_found: 'Página não encontrada',
            news: 'novos',
            commented: 'comentados',
        },
        categoriesList: {
            categories: 'Categorias',
            categories_letter: 'Categorias letra',
            categories_letter_starts1: 'Categorias que começam com a letra',
            categories_letter_starts2: 'são exibidas nesta página',
            md: 'OKdescontos',
            category_md: 'Categorias | OKdescontos',
        },
        categoryDetails: {
            offers: 'Promoções',
            comments: 'Comentários',
            no_offers_found1: 'No momento, não temos',
            no_offers_found2: ', em breve estarão disponíveis novamente.',
            check_offers: 'Enquanto isso, pode conferir mais promoções',
            best_offers: 'As melhores promoções aqui',
            description: 'Descrição',
            similar_categories: 'Categorias semelhantes',
            popular_stores: 'Lojas populares',
            populars: 'Populares',
            news: 'Novos',
            new_offers_in: 'Novas promoções em',
            commented: 'Comentados',
            best_week_discounts: 'Os melhores cupons e promoções da semana',
            in: 'em',
        },
        discountDetails: {
            coupon_may_not_work: 'O cupom pode não funcionar, foi publicado há mais de 1 mês.',
            offer_may_not_work: 'A oferta pode não funcionar, foi publicada há mais de 1 mês.',
            expand: 'Expandir',
            published_about: 'Publicado há',
            updated_at: 'Atualizado há',
            see_more: 'Ver mais',
            offers: 'Promoções',
            how_participate: 'Como participar?',
            how_much_times_participate: 'Quantas vezes pode-se participar',
            member_since: 'Membro desde:',
            details: 'Detalhes',
            information: 'Informação',
            ends: 'Termina',
            exclusive: 'Exclusividade',
            verified: 'Verificado',
            start_at: 'Inicia',
            end_at: 'Finaliza',
            local: 'Local',
            sended_from: 'Enviado desde',
            categories: 'Categorias',
            share: 'Compartilhar',
            affiliate_comission:
                'A OKdescontos pode receber uma comissão de afiliados ao clicar ou comprar através do site. Agradecemos por economizar conosco.',
            related_coupons: 'Cupons relacionados',
            related_offers: 'Promoções relacionadas',
            no_exist: 'Não há',
            similar_by_now: ' semelhantes por enquanto',
            comments: 'Comentários',
            following: 'Seguindo',
            follow: 'Seguir',
            read_more: 'Ler mais',
            more_relevant: 'Mais relevante',
            more_recent: 'Mais recentes',
            no_comments_yet: 'Ainda não há comentários',
            be_first_commenting: 'Seja o primeiro a comentar e ganhe curtidas no seu comentário',
            no_offer_found: 'Esta oferta não foi encontrada',
            unexpected_error: 'Ocorreu um problema inesperado, tente novamente mais tarde',
            today: 'HOJE',
            tomorrow: 'AMANHÃ',
            in_3_days: 'em 3 dias',
            see_comments_relevant: 'Ver os comentários mais relevantes',
            see_all_comments_more_recent_first:
                'Ver todos os comentários, os comentários mais recentes são exibidos primeiro',
            save: 'Salvar',
            report: 'Denunciar',
            why_report: 'Por que você está reportando?',
            spam_ofensive: 'Spam/Ofensivo',
            duplicated: 'Duplicado',
            offer_is_published: 'A oferta já foi publicada',
            refered_links: 'Links de referência',
            expired_offer_or_link_not_working: 'A oferta expirou ou o link não está funcionando',
            other: 'Otro',
            free_ship: 'Frete grátis',
            free_ship_uppercase: 'FRETE GRÁTIS',
            free: 'GRÁTIS',
            home: 'Início',
            error_at: 'Error ao',
            delete: 'excluir',
            offer: 'Oferta',
            coupon: 'Cupón',
            coupons: 'cupons',
            save_min: 'guardar',
            something_happens_with: 'algo errado com',
            this_offer: 'esta oferta',
            this_coupon: 'este cupom',
            saved_a: 'guardada',
            saved: 'guardado',
            deleted: 'eliminado',
            deleted_a: 'eliminada',
            close: 'Fechar',
            error_on_delete_offer: 'Erro ao excluir, algo de errado com esta oferta',
            error_on_save_coupon: 'Erro ao salvar, algo de errado com este cupom',
            okay: 'Entendido',
            report_success: 'Seu relato foi enviada com sucesso',
            report_error: 'Não foi possível reportar',
            comment_published: 'Comentário publicado com sucesso',
            comment_pending: 'Comentário pendente de aprovação',
            hide: 'Esconder',
            comment_cant_create: 'Não foi possível criar o comentário',
        },
        discountMask: {
            press_to_copy: 'Clique no código para copiá-lo',
            code_copied_success: 'Código copiado com sucesso!',
            bring: 'Vamos te levar',
            when_copy_code: 'quando você copia o código',
            moving_in: 'levando você',
            in: 'em',
            remember_apply_code: 'Lembre-se de aplicar o código ao finalizar a compra',
            expired_coupon: 'Parece que este cupom expirou',
            error_obtaining_coupon:
                'Ocorreu um erro ao obter os dados do cupom, por favor, entre em contato com o suporte',
            unexpected_error: 'Ocorreu um problema inesperado, por favor, tente novamente mais tarde',
            no_coupon_required: 'NÃO É NECESSÁRIO CUPOM',
            go_to: 'Ir para',
            go_store: 'Ir para a loja',
        },
        homepage: {
            best_week_offers: 'Cupons de descontos ativos e testados',
            best_offers_and_coupons_from: 'As melhores promoções e cupons',
            top_categories: 'Top categorias',
            popular_stores: 'Lojas Populares',
            see_more: 'Ver mais',
            popular: 'Populares',
            news: 'Novos',
            commented: 'Comentados',
        },
        storeDetails: {
            coupons_of: 'Códigos de desconto :store',
            we_dont_have: 'Neste momento não temos',
            in_the_store: 'de Desconto',
            coupons: 'Cupons',
            featured_weekly_offers: 'Melhores descontos :store',
            best_coupons_here: 'Mejores cupons aqui',
            will_be_available: 'em breve estarão disponíveis novamente',
            best_coupons: 'Enquanto isso, você pode revisar os melhores cupons',
            offers: 'Promoções',
            check_offers: ', em breve estarão disponíveis novamente',
            best_offers: 'Melhores promoções aqui',
            recent_coupons: 'Cupons recentes de',
            discount: 'Desconto',
            details: 'Detalhes',
            discount_details: 'do desconto',
            expires: 'Expira',
            free_ship: 'Envio Grátis',
            free: 'GRÁTIS',
            offers_and_coupons_expired_from: 'Promoções e cupons expirados de',
            promotions_from: 'Promoções de',
            discount_resume: 'Resumo de descontos',
            similar_stores: 'Lojas semelhantes',
            popular_stores: 'Lojas populares',
            home: 'Início',
            stores: 'Lojas',
            valid_until_advice: 'Válido até nova ordem',
        },
        storesBridge: {
            category_not_found: 'Esta categoria não foi encontrada',
            store_not_found: 'Esta loja não foi encontrada',
            error: 'Ocorreu um problema inesperado, tente novamente mais tarde',
        },
        storesList: {
            popular_stores: 'Lojas Populares',
            stores_letter: 'Lojas que começan con a letra',
            md: '| OKdescontos',
            stores: 'Lojas',
            stores_with_letter1: 'Todas as lojas que começam com a letra',
            stores_with_letter2: 'são exibidas nesta página.',
        },
    },
    pages: {
        subscribe: {
            success: 'Satisfatório',
            ops: 'Oops...',
            congrats: 'Parabéns! Você já está inscrito em nossa newsletter!',
            existing: 'Aparentemente você já estava inscrito em nossa newsletter!',
            error: 'Parece que ocorreu um erro ao tentar se inscrever, mas você pode tentar novamente daqui a pouco.',
            title: 'Inscrição | OKdescontos',
            description: 'subscrição da newsletter OKdescontos',
        },
        unsubscribe: {
            success: 'Satisfatório',
            ops: 'Oops...',
            congrats: 'Você acabou de cancelar a inscrição em nossa newsletter',
            error: 'Parece que houve um erro ao tentar cancelar a inscrição, mas você pode tentar novamente daqui a pouco.',
            title: 'Cancelar subscrição | OKdescontos',
            description: 'Cancelar a subscrição da newsletter OKdescontos',
        },
        blogArticle: {
            index: 'Índice',
            valorate: 'Avalie',
            similar_articles: 'Itens similares',
            event_not_found: 'Este evento no fue encontrado',
            error: 'Ocorreu um problema inesperado, tente novamente mais tarde',
            hide: 'Ocultar',
            error_on_valorate: 'Ocorreu um erro ao classificar o item',
            home: 'Início',
            blog: 'Blog',
        },
        blogCategory: {
            all_articles_about: 'Todos os artigos sobre',
            category_no_contents: 'Esta categoria ainda não tem conteúdo',
            category_not_found: 'Esta categoria não foi encontrada',
            unexpected_error: 'Ocorreu um problema inesperado, tente novamente mais tarde',
            articles_about: 'Artigos sobre {{category}} | OKdescontos',
            all_articles: 'Aqui você encontra todos os artigos sobre {{category}} na OKdescontos',
            home: 'Início',
            blog: 'Blog',
        },
        blog: {
            advices: 'Dicas ',
            and: 'e',
            guides: 'guias',
            to_save_money: 'para economizar dinheiro',
            best_guides:
                'Os melhores guias de economia de dinheiro do Brasil com uma equipe dedicada de especialistas.',
            articles_about: 'Artigos sobre',
            see_more: 'Ver mais',
        },
        compartirCuponCrear: {
            at_coupon: 'do cupom',
            title_seo: 'Compartilhar un cupom | OKdescontos',
            description_seo: 'Página para compartilhar os seus cupons',
            error_on_create: 'Erro ao criar cupom, tente novamente',
            coupon_created: 'Cupom criado com sucesso',
        },
        compartirCupon: {
            title_seo: 'Compartilhar un cupom | OKdescontos',
            description_seo: 'Página para compartilhar os seus cupons',
        },
        compartirOfertaCrear: {
            of_the_offer: 'da promoção',
            title_seo: 'Compartilhar uma promoção | OKdescontos',
            description_seo: 'Página para Compartilhar suas promoções',
            error_on_create: 'Erro ao criar promoção, tente novamente',
            offer_success: 'Promoção criada com sucesso',
        },
        compartirOferta: {
            title_seo: 'Compartilhar uma promoção | OKdescontos',
            description_seo: 'Página para compartilhar suas promoções',
        },
        compartirChat: {
            chat: 'Bate-papo',
            title_seo: 'Compartilhar chat | OKdescontos',
            description_seo: 'Página para compartilhar um chat',
            error_on_create: 'Erro ao criar o chat, tente novamente',
        },
        compartirFinalizado: {
            post_received: 'Recebemos o seu',
            in_revision: 'e está em análise.',
            when_published: 'Quando for aprovado você receberá uma notificação.',
            be_patient: 'Seja paciente, iremos analisá-lo em breve',
            recommend: 'Enquanto o seu {{post}} não for publicado, recomendamos:',
            see_offers: 'Ver Promoções Populares',
            see_coupons: 'Ver Cupons Populares',
            your: 'Seu',
            published: 'foi publicado com sucesso!',
            see_post: 'Aqui você pode ver seu',
            title_seo: 'Finalizado! | OKdescontos',
            description_seo: 'A publicação foi bem sucedida',
        },
        compartir: {
            share_question: 'O que você gostaria de compartilhar?',
            offer: 'Promoção',
            share_offer: 'Compartilhe uma promoção atraente',
            coupon: 'Cupom',
            share_coupon: 'Compartilhe um cupom e não esqueça de incluir o código de desconto',
            share_if_socials: 'Se você viu um sorteio nas redes sociais ou em algum lugar, compartilhe',
            chat: 'Bate-papo e discussões',
            open_thread: 'Abra uma discussão ou pergunte qualquer coisa à comunidade',
            title_seo: 'Compartilhar | OKdescontos',
            description_seo:
                'Página para compartilhar suas publicações como promoções, cupons, sorteios e bate-papo...',
        },
        cupones: {
            featured_coupons: 'Cupons destacados',
            featured_stores: 'Lojas destacadas',
            description: 'Descrição',
            featured_shop: 'Lojas Populares',
            populars: 'Populares',
            news: 'Novos',
            commented: 'Comentados',
            home: 'Início',
            coupons: 'Cupons',
        },
        dashboardUserActividad: {
            recent_activity: 'Atividade recente',
            no_activity: 'Nenhuma atividade ainda',
            title_seo: 'Painel de {{name}} | OKdescontos',
            description_seo: 'Atividades recentes que {{name}} fez em sua conta',
        },
        dashboardUserChats: {
            chats: 'Bate-papos',
            error: 'Ops! Aparentemente este usuário ainda não criou ou participou de bate-papo',
            title_seo: 'Painel de {{name}} | bater papo | OKdescontos',
            description_seo: 'Bate-papos/fóruns nos quais {{name}} postou OKdescontos',
        },
        dashboardUserDescuentos: {
            offer_coupons_published: 'Promoções/cupons publicados',
            no_discounts: 'Este usuário ainda não publicou promoções/cupons',
            title_seo: 'Painel de {{name}} | Descontos | OKdescontos',
            description_seo: 'Descontos que {{name}} postou em OKdescontos',
        },
        dashboardUserIndex: {
            see: 'Ver',
            recent_activity: 'Atividade recente',
            discounts_published: 'Promoções/cupons publicados',
            chats: 'Chats',
            title_seo: 'Painel do usuário {{name}} | OKdescontos',
            description_seo: 'Painel do usuário {{name}}',
        },
        dashboardUserMain: {
            member_since: 'Membro desde:',
            followers: 'Seguidores',
            rank: 'Ranking de usuário',
            follow: 'Seguir',
            rewards: 'Recompensas',
            stats: 'Estatísticas',
            verified_user: 'Usuário verificado',
            user_was_verified: 'Seu usuário já foi verificado',
            verified_description:
                'Você é um usuário que recebeu o selo "Verificado" após ter 25 promoções/cupons e 50 comentários aprovados. Você deverá receber um e-mail para informá-lo, além de ser notificado por mensagens e notificações.',
            offer_coupon: 'Cupons/promoções',
            comments: 'Comentários',
            profile_not_found: 'Perfil do usuário não encontrado',
            coins_available: 'Moedas disponíveis',
            coins_total: 'Total de moedas',
            awards: 'Prêmios',
            chats: 'Chats',
            likes_sended: 'Curtidas enviadas em promoções e comentários',
        },
        dashboardActividad: {
            recent_activity: 'Atividade recente',
            no_activity: 'Você ainda não possui atividades',
            publish_offer: 'Publicar promoção',
            publish_coupon: 'Publicar cupom',
            title_seo: 'Seu painel | OKdescontos',
            description_seo: 'Atividades recentes que você fez em sua conta',
        },
        dashboardAjustes: {
            account: 'Minha conta',
            username: 'Nome de usuário',
            change: 'Alterar',
            password: 'Senha',
            set_new_password: 'Definir nova senha',
            new_password: 'Nova Senha',
            email: 'e-mail',
            not_verified: 'Não verificado (Verifique seu e-mail)',
            resend_email: 'Reenviar e-mail de verificação',
            delete_account: 'Excluir conta',
            notifications: 'Notificações',
            notifications_web: 'Notificações web',
            notifications_email: 'Notificações por email',
            new_novelties_post: 'Novo post de notícias foi publicado.',
            new_private_message: 'Nova mensagem na sua caixa de entrada.',
            coins_received: 'Recebi moedas.',
            comments: 'Comentários',
            someone_answers_to_comment: 'Alguém respondeu meu comentário.',
            multiple_likes_to_comment: 'Vários usuários curtem um comentário meu',
            my_comment_liked: 'Alguém curtiu um comentário meu.',
            offers_coupons: 'Promoções/cupons',
            discount_first_grade: 'Minha promoção/cupom atinge o primeiro grau de temperatura.',
            discount_popular_250: 'Minha promoção/cupom é popular (+250º)',
            discount_popular_500: 'Minha promoção/cupom é popular (+500º)',
            discount_other_popular_500: 'Uma promoção/cupom de outra pessoa é muito popular (+500º)',
            discount_not_popular_minus_25: 'Minha promoção/cupom não é popular (-25°)',
            discount_expired: 'Uma promoção/cupom expirou.',
            report_publication_approved: 'Minha denúncia de postagem foi aprovada.',
            report_publication_rejected: 'Minha denúncia de postagem foi rejeitada.',
            specials: 'Especiais',
            follow_telegram: 'Seguir canal do Telegram',
            go_telegram: 'Ir ao canal do Telegram',
            telegram_description:
                'Neste canal do Telegram, enviamos as melhores promoções, cupons e sorteios todos os dias, um resumo da melhor seleção para que você não precise acessar a web todas as vezes.',
            email_offers: 'Promoções por e-mail',
            receive_best_offers_daily: 'Receba notificações das melhores promoções diárias.',
            receive_best_offers_week: 'Receba Notificações das melhores promoções da semana.',
            receive_recomendations: 'Receba notificações de recomendações da OKdescontos.',
            newsletter: 'Newsletter',
            receive_newsletter: 'Receba notificações da newsletter.',
            receive_no_readed_notifications: 'Receba notificações não lidas semanalmente.',
            general: 'Geral',
            novelties_published: 'Novo post de notícias foi publicado.',
            inbox_received: 'Nova mensagem na sua caixa de entrada.',
            when_someone_tags_in_comment: 'Quando alguém me marca em um comentário.',
            when_someone_answer_comment: 'Quando alguém responde a um comentário meu.',
            when_post_receives_first_comment: 'Quando um post meu recebe o primeiro comentário.',
            when_post_receives_commments: 'Quando um post meu recebe comentários.',
            my_comment_approved: 'Seu comentário foi aprovado.',
            when_discount_popular_250: 'Quando minha promoção/cupom é popular (+250º).',
            when_discount_popular_500: 'Quando minha promoção/cupom é muito popular (+500º).',
            when_discount_minus_25: 'Quando minha promoção/cupom não é popular (-25°).',
            when_other_discount_popular_500:
                'Quando uma promoção/cupom de outra pessoa é muito popular (+500º).',
            when_discount_expired: 'Quando uma promoção/cupom meu expirou.',
            when_discount_published: 'Quando minha promoção/cupom for publicada.',
            save_settings: 'Salvar configurações',
            social_connection: 'Conexão social',
            created_accounts: 'Contas conectadas',
            google_connected: 'Conectado ao Google',
            facebook_connected: 'Conectado ao Facebook',
            preferences_get_error: 'Falha ao obter as preferências do usuário',
            title_seo: 'Definições | OKdescontos',
            description_seo: 'Configuração da conta',
            google_connected_account: 'Conta Google conectada:',
            google_not_connected: 'Você não tem sua conta do Google conectada.',
            facebook_connected_account: 'Conta Facebook conectada:',
            facebook_not_connected: 'Você não tem sua conta do Facebook conectada.',
            success_account_image: 'Sua foto de perfil foi atualizada com sucesso',
            error_account_image: 'Erro ao atualizar sua foto de perfil',
            success_updating_preferences_web:
                'As preferências de notificações da Web foram atualizadas com sucesso',
            error_updating_preferences_web: 'Erro ao atualizar as preferências de Notificações da Web',
            success_updating_preferences_email: 'Preferências de e-mail atualizadas com sucesso',
            error_updating_preferences_email: 'Erro ao atualizar as preferências de e-mail',
            email_verification_sended: 'Enviamos um e-mail de verificação',
            are_sure_delete_account: 'Tem certeza de que deseja excluir sua conta?',
            success_deleting_account: 'Conta excluída com sucesso',
            error_deleting_account: 'Erro ao tentar excluir conta',
            cancel: 'Cancelar',
        },
        dashboardDescuentos: {
            discounts_published: 'Ofertas/cupons publicados',
            no_discounts_published: 'Você ainda não publicou promoções/cupons',
            publish_one: '¡Comece a postar agora!',
            title_seo: 'Descontos | OKdescontos',
            description_seo: 'Descontos que você publicou em OKdescontos',
        },
        dashboardGuardados: {
            discounts_saved: 'Promoções/cupons salvos',
            no_discounts_saved: 'Você ainda não tem ofertas/cupons salvos',
            title_seo: 'Salvos | OKdescontos',
            description_seo: 'Descontos que você salvou em sua conta',
        },
        dashboardParent: {
            see: 'Ver',
            recent_activity: 'Atividade recente',
            my_discounts_published: 'Minhas ofertas e cupons publicados',
            discounts_published: 'minhas ofertas e cupons publicados',
            discounts_saved: 'Ofertas/cupons guardados',
            my_chats: 'Meus chats',
            settings: 'Configurações',
            rank: 'rank',
            my_title_seo: 'Seu painel | OKdescontos',
            my_description_seo: 'Seu painel de usuário',
            user_verified: 'Usuário verificado',
            Statistics: 'Estatisticas',
            followers: 'Seguidores',
            member_since: 'Membro desde:',
            ranking: 'Ranking de usuario',
            rewards: 'Recompensas',
            stats: 'Estatísticas',
            verified_user: 'Usuário verificado',
            user_was_verified: 'Seu usuário já foi verificado',
            verified_description:
                'Você é um usuário que possui o selo "Verificado" após 25 ofertas/cupons e 50 comentários serem aprovados. Você deve receber um e-mail para informá-lo e também será notificado por mensagens e notificações.',
            discounts: 'Cupons/ofertas',
            my_discounts: 'Meus cupons/ofertas',
            comments: 'Comentários',
            my_comments: 'Meus comentários',
            account: 'Minha conta',
            notifications: 'Notificações',
            social_connection: 'Conexão social',
            close_session: 'Fechar sessão',
            profile_not_found: 'Perfil de usuário não encontrado',
            coins_available: 'Moedas disponíveis',
            coins_total: 'Total de moedas',
            awards: 'Prêmios',
            chats: 'Bate-papo',
            follow: 'Seguir',
            likes_sended: 'Likes enviados em ofertas e comentários',
            title_seo: 'Painel de controle | OKdescontos',
            description_seo: 'Painel do usuário',
            session_closed_forced: 'Sessão fechada de maneira forçada',
        },
        dashboardMonedas: {
            coins: 'Moedas',
            action: 'Ação',
            user_not_verified: 'Usuário não verificado',
            verified_user: 'Usuário verificado',
            information: 'Informação',
            points: 'pontos',
            coins_quantity: 'Moedas',
            historical_events: 'Histórico de eventos',
            date: 'Data',
            received_points: 'Pontos ganhos',
            no_coins_earned: 'Você ainda não ganhou nenhuma moeda',
            faq: 'Perguntas frequentes',
            register_login: 'Cadastro e login',
            action_1_time: 'Ação: 1 vez.',
            comments: 'Comentários',
            action_5_time_per_day: 'Ação: 5 vezes ao dia.',
            report_expired_offer: 'Denunciar promoção expirada',
            action_5_time_per_day_approved: 'Ação: 5 vezes por dia (aprovado).',
            offer_published: 'promoção publicada',
            action_1_time_per_day_if_approved: 'Ação: 1 vez por dia (se aprovado).',
            coupon_published: 'Cupom publicado',
            action_no_limits: 'Ação: ilimitada.',
            discount_reach_250: 'Promoção/cupom alcança 250°',
            discount_reach_500: 'Promoção/cupom alcança 500°',
            check_in_daily: 'Check-in diário',
            action_1_time_per_day: 'Ação: 1 vez ao dia.',
            antiquity_1_month: 'Usuário atinge 1 mês de antiguidade',
            antiquity_6_month: 'Usuário atinge 6 meses de antiguidade',
            aniversary: 'Aniversário do usuário',
            action_1_time_anually: 'Ação: 1 vez ao ano.',
            coins_are_free: 'As moedas são gratuitas?',
            coins_are_free_answer:
                'Sim, a obtenção de moedas é totalmente gratuita e é adquirida participando da comunidade, acessando, comentando e publicando ofertas, cupons ou discussões, entre muitas outras formas de obtê-las',
            can_buy_coins: 'É possível comprar moedas extras?',
            can_buy_coins_answer:
                'Não, pelo menos por enquanto. Mas se você verificar seu usuário (nível 1), quase sempre receberá o dobro de moedas para cada ação que realizar. Para ser um usuário verificado, você só precisa ter 25 descontos e 50 comentários publicados.',
            how_can_use_coins: 'Como posso gastar minhas moedas?',
            how_can_use_coins_answer:
                'Quando você tiver moedas suficientes, vá para a seção Prêmios e você verá automaticamente as disponíveis para solicitá-las. Uma vez solicitado, vamos verificar e aprovar o seu prêmio (caso você atenda aos requisitos).',
            title_seo: 'Moedas | OKdescontos',
            description_seo:
                'Saiba mais sobre as moedas que podemos oferecer a você para a obtenção de mais descontos OKdescontos',
        },
        dashboardPremios: {
            awards: 'Prêmios',
            available_awards: 'Prêmios disponíveis',
            historical_awards: 'Histórico de Prêmios ganhados',
            points: 'Pontos',
            redeem: 'Resgatar prêmio',
            no_prizes_available: 'No momento não há prêmios disponíveis',
            come_back_later:
                'Volte mais tarde para tentar trocar suas moedas por prêmios. Eles estarão disponíveis em breve.',
            coins: 'Moedas',
            award_redeemed: 'Prêmio resgatado',
            no_prizes_achieved: 'Você não ganhou nenhum prêmio ainda',
            keep_farming:
                'Continue recebendo moedas e aqui você verá seus prêmios ganhos muito em breve. Participe, compartilhe e comente para ser um dos melhores usuários',
            title_seo: 'Prêmios | OKdescontos',
            description_seo: 'Prêmios disponíveis em OKdescontos',
            redeem_success_awaiting: 'Prêmio resgatado, aprovação pendente',
            close: 'Fechar',
            error_on_redeem: 'Erro ao resgatar prêmio',
        },
        eventosSlug: {
            featured_stores: 'Lojas em destaque',
            featured_discounts: 'Descontos em destaque',
            no_discounts: 'Ainda não há ofertas ativas, volte mais tarde',
            enjoy: 'Aproveite TODOS os cupons e descontos em',
            in_stores: 'nestas Lojas',
            event_not_found: 'Este evento não foi encontrado',
            unexpected_error: 'Ocorreu um problema inesperado, tente novamente mais tarde',
            home: 'Início',
            events: 'Eventos',
        },
        eventosIndex: {
            active_events: 'Eventos ativos',
            no_active_events: 'Parece não haver nenhum evento ativo',
            next_events: 'Próximos eventos',
            not_events_found: 'Não encontramos eventos disponíveis para o próximo ano',
            missed_events: 'Eventos que você perdeu',
            no_expired_events: 'Parece que não há eventos expirados',
            no_discounts_active: 'Ainda não há descontos ativos, volte mais tarde',
            save: 'Economize com cupons exclusivos da OKdescontos',
        },
        foros: {
            threads: 'Tópicos',
            create_new_forum: 'Criar novo tópico',
            forum: 'Fórum',
            popular_shops: 'Lojas populares',
            home: 'Inicio',
            categories: 'Categorias',
        },
        forosSlug: {
            ago: 'Desde',
            comments: 'Comentários',
            following: 'Seguindo',
            follow: 'Seguir',
            more_relevant: 'Mais relevante',
            more_recents: 'Mais recentes',
            no_comments_yet: 'Ainda não há comentários.',
            comment_first: 'Seja o primeiro a comentar e ganhe curtidas no seu comentário',
            discussions: 'Discussões',
            see_more: 'Ver mais',
            thread_not_found: 'Este tópico não foi encontrado',
            unexpected_error: 'Ocorreu um problema inesperado, tente novamente mais tarde',
            years: 'anos',
            months: 'meses',
            weeks: 'semanas',
            days: 'días',
            hours: 'horas',
            minutes: 'minutos',
            seconds: 'segundos',
            see_more_relevant_comments: 'Ver comentários mais relevantes',
            see_all_comments: 'Ver todos os comentários. Os comentários mais recentes são exibidos primeiro',
            report: 'Denunciar',
            why_report: 'Por que você denuncia?',
            spam_ofensive: 'Spam/Ofensivo',
            duplicated: 'Duplicado',
            offer_published: 'A promoção já está publicada',
            refered_links: 'Links de referência',
            other: 'Outro',
            home: 'Início',
            forums: 'Fórums',
            new: 'Novo',
            popular: 'Popular',
            hide: 'Ocultar',
            published_comments: 'Comentário postado com sucesso',
            pending_comment: 'Comentário pendente de aprovação',
            comment_error: 'Não foi possível criar o comentário',
            okay: 'De acordo',
            report_success: 'Sua denúncia foi enviada com sucesso',
            close: 'Fechar',
            report_error: 'A denúncia não pôde ser feita',
        },
        mensajeria: {
            inbox: 'Caixa de entrada',
            send_message: 'Enviar',
            no_readed_conversations: 'Nenhuma conversa iniciada ainda',
            load_more_messages: 'Carregar mais mensagens',
            write_your_message: 'Escreva a sua mensagem aqui',
            send: 'Enviar',
            to_start: 'Para iniciar a conversar, selecione um bate-papo ou inicie um',
            title_seo: 'Mensagens | OKdescontos',
            description_seo: 'Página para visualizar e enviar mensagens entre usuários',
            delete: 'Eliminar',
            delete_conversation: 'Eliminar esta conversa',
            want_delete_conversation: 'Deseja excluir esta conversa?',
            conversation_deleted: 'Conversa excluída',
            hide: 'Ocultar',
            error: 'Ocorreu um erro ao excluir a conversa',
            cancel: 'Cancelar',
            mute_conversation: 'Silenciar notificações nesta conversa',
            unmute_conversation: 'Ativar as notificações nesta conversa',
            want: 'Quieres',
            this_conversation: 'esta conversa?',
            conversation_muted: 'Conversación silenciada',
            error_on_conversation_mute: 'Ocurrió un error al silenciar la conversación',
            unmute_may: 'Desilenciar',
            mute_may: 'Silenciar',
            unmute: 'desilenciar',
            mute: 'silenciar',
            unexpected_error: 'Ocorreu um erro inesperado, tente novamente em alguns minutos',
            error_on_send_message: 'Um erro ocorreu ao enviar esta mensagem',
        },
        novedadesSlug: {
            last_novelties: 'Últimas novidades',
            novelty_not_found: 'Esta novidade não foi encontrada',
            unexpected_error: 'Ocorreu um problema inesperado, tente novamente mais tarde',
            home: 'Início',
            novelties: 'Novidades',
        },
        novedades: {
            novelties: 'Novidades',
            no_novelties: 'Nenhuma novidade disponível ainda',
        },
        ofertas: {
            featured_offers: 'Promoções em destaque',
            popular_brands: 'Lojas destacadas',
            description: 'Descrição',
            popular_stores: 'Lojas Populares',
            populars: 'Populares',
            news: 'Novos',
            commented: 'Comentados',
            home: 'Início',
            offers: 'Ofertas',
        },
        acercaDe: {
            meet: 'Conheça a OKdescontos, a plataforma nº 1 no Brasil',
            us: 'Nós',
            hi: 'Olá, a todos! Sou o Jotah, administrador da OKdescontos no Brasil. Em conjunto com a minha equipe criamos esta plataforma como alternativa a outras já existentes, pois acreditamos que uma plataforma deste tipo deve recompensar os usuário pela sua atividade e iremos fazê-lo. Além disso, realizaremos sorteios frequentes, ações nas redes sociais e também continuaremos desenvolvendo funcionalidades para a OKdescontos com seções VIP, eventos, novas formas de ver ofertas/cupons e muito mais. Recomendamos que você seja um usuário ativo porque vamos recompensá-lo com muitas surpresas. Inscreva-se e participe, você não vai se arrepender. E você pode me contatar no meu perfil do Instagram: @jotahmega',
            how_works: 'Como funciona!',
            share_discounts: 'Compartilhe ofertas, cupons, brindes ou discussões com a comunidade',
            participate: 'Participe de conversas e interaja com outros membros',
            every_action: 'Para cada ação você ganhará moedas que poderá trocar por prêmios',
            join_us: 'Junte-se a OKdescontos',
            our_vision: 'Nossa visão',
            transform:
                'Queremos transformar a forma de encontrar descontos no Brasil e na América Latina através de uma plataforma completa, na qual vamos incluir lojas online e pequenas empresas e alcançar colaborações com lojas para que possam nos oferecer as melhores ofertas e cupons.',
            together:
                'Junto com nossa equipe e vocês, membros, vamos alcançar a maior comunidade de descontos da América Latina. E você será recompensado com prêmios e brindes, desde que participe e interaja.',
            our_team: 'Nossa equipe',
            team_description:
                'A equipe da OKdescontos, como você pode imaginar, é muito grande! Para manter a plataforma em um nível técnico em todas as áreas, também requer o pessoal do web design e uma grande equipe de editores e moderadores de conteúdo, eles são responsáveis pela revisão de todo o conteúdo, comentários e discussões. Sem toda essa equipe não seria possível gerar a  OKdescontos:)',
        },
        contacto: {
            contact: 'Entre em contato',
            title: 'Título',
            details: 'Insira os detalhes da oferta (loja, desconto e o que quiser)',
            affair: 'Assunto',
            name: 'Nome',
            required: 'Este campo é obrigatório',
            email_address: 'e-mail',
            message: 'Mensagem',
            remember: 'Lembre-se que você está entrando em contato com a OKdescontos e não com uma loja',
            send_message: 'Enviar mensagem',
            why_contact: 'Sobre qual assunto você está nos contatando?',
            share_offer: 'Compartilhar oferta',
            account: 'Minha conta',
            comercial: 'Comercial',
            suggests: 'Sugestões',
            complaints_legal: 'Reclamações e Jurídico',
            others: 'Outros',
            message_error: 'Ocorreu um erro. Por favor tente novamente mais tarde',
            message_success: 'Sua mensagem foi enviada com sucesso',
        },
        contrasenaActualizadaExitosamente: {
            success: 'Senha atualizada corretamente',
            enjoy: 'Agora aproveite as ofertas/cupons em OKdescontos',
            see_coupons: 'Ver cupons',
            see_offers: 'Ver ofertas',
            redirect: 'Você será redirecionado para fazer login em segundos',
            title_seo: 'Senha atualizada com sucesso | OKdescontos',
            description_seo: 'Página de senha atualizada com sucesso',
        },
        expirados: {
            lost: 'Olha o que você está perdendo',
            expired_recently: 'Ofertas e cupons que expiraram recentemente',
            no_discounts_expired: 'Não há ofertas/cupons expirados',
            found_discounts: 'Encontre ofertas e cupons em outras pesquisas',
            featured_stores: 'Lojas Populares',
            coupons: 'Mega Cupons',
            offers: 'Mega ofertas',
            categories: 'Categorias',
            brands: 'Lojas',
            register: 'Registra-se',
            popular: 'Populares',
            news: 'Novos',
            commented: 'Comentados',
            all_coupons:
                'Todos os cupons e ofertas expiraram recentemente em :mês :ano. No caso de você ter perdido alguma coisa.',
        },
        extensionDesinstalada: {
            sorry: 'Lamentamos ver você ir',
            mistake: 'Desinstalou por engano?',
            reinstall: 'Reinstalar extensão',
            why: 'Se você fez isso de propósito, ajude-nos a melhorar dizendo por que você desinstalou a OKdescontos',
            select: 'Marque as opções que se aplicam',
            think: 'Diga-nos o que você pensa.',
            other_comments: 'Que outros comentários você tem para nós?',
            send: 'Enviar',
            by_mistake: 'Instalei por engano.',
            codes_not_work: 'Os códigos não funcionaram',
            md_not_work: 'OKdescontos não funciona onde compro.',
            md_confuse: 'Achei a OKdescontos algo confuso.',
            savings_useless: 'As economias não ajudaram.',
            other: 'Outro.',
            title_seo: 'Extensão desinstalada | OKdescontos',
            description_seo: 'Página de extensão desinstalada',
            error: 'Erro ao enviar dados',
            pick_one: 'Você deve selecionar pelo menos uma opção ou nos deixar um comentário.',
            close: 'Fechar',
        },
        extension: {
            amazing_discounts: 'Descontos incríveis e 100% grátis com a extensão da OKdescontos',
            find_codes:
                'Você gosta de fazer compras online? Encontrar códigos e ofertas para suas lojas favoritas agora é mais fácil e gratuito.',
            install: 'Instale agora gratuitamente',
            free: 'É grátis',
            free_no_cost: '100% gratuito e sem custos adicionais, além de atualizações frequentes.',
            apply: 'Aplicar automáticamente',
            we_tell_auto: 'Notificaremos você automaticamente quando encontrarmos cupons e ofertas.',
            register_not_required: 'Não é necessário registro',
            install_and_go: 'Basta instalar e está pronto. Você não precisa se registrar nem perder tempo.',
            best_extension: 'A melhor extensão de ofertas e cupons',
            developing:
                'Estamos desenvolvendo a melhor extensão de navegador de cupons e ofertas do Brasil, para que você possa economizar em suas lojas favoritas e não perder tempo procurando descontos que não funcionam mais. Instale hoje e comece a economizar.',
            find_best_savings: 'Encontre as melhores economias',
            brands: 'Rappi, Beleza Na Web, AliExpress, Amazon e muito mais',
            best_brands:
                'As melhores lojas do Brasil são encontradas na OKdescontos, com os melhores cupons e ofertas. Encontre promoções para lojas como, Rappi, AliExpress ou adidas Brasil, entre muitas outras. Já estão disponíveis mais de 250 lojas. Vai perder?',
            get_now: 'Obtenha agora!',
            download_extension: 'Baixar extensão',
            extension_free:
                'A extensão é totalmente gratuita. Não há assinaturas, complementos ou surpresas desagradáveis. Experimente hoje.',
            answers_here: 'Aqui estão as respostas para suas perguntas...',
            is_extension_free: 'A extensão é gratuita?',
            is_extension_free_answer:
                'Sim, 100% gratuita e sem registro. Basta instalar a extensão e começar a economizar em suas lojas favoritas.',
            how_install_extension: 'Como instalar a extensão da OKdescontos?',
            how_install_extension_answer:
                'É muito fácil. Você só precisa procurar OKdescontos na sua loja Google Chrome ou Firefox e instalá-lo.',
            how_know_works: 'Como posso saber se funciona bem?',
            how_know_works_answer:
                'Ao visitar uma das lojas associadas, mostraremos automaticamente a extensão e você começará a ver os cupons. Ou se você abri-lo manualmente, ele informará se há alguma oferta nessa loja em questão.',
            what_shops_discounts: 'Quais lojas têm descontos?',
            what_shops_discounts_answer:
                'Temos cupons e ofertas para as principais lojas como Amazon, Rappi, Linio ou Walmart, entre muitas outras.',
            how_remove: 'Como a extensão é removida?',
            how_remove_answer:
                'Não recomendamos desinstalá-lo porque você perderá muitas economias em compras online. Mas se você quiser fazer isso, clique com o botão direito e selecione “Desinstalar”',
            works_mobile: 'Funciona no celular?',
            works_mobile_answer:
                'Estamos trabalhando para que funcione no Safari. Iremos notificá-lo pelas novidades do site.',
            title_seo: 'Extensão | OKdescontos',
            description_seo: 'Página de extensão',
        },
        gratis: {
            products: 'Produtos e serviços',
            free: 'GRATUITOS',
            in_mexico: ' no Brasil',
            description: 'Descrição',
            featured_stores: 'Lojas Populares',
            populars: 'Populares',
            news: 'Novos',
            home: 'Início',
            stores: 'Lojas',
        },
        inicioSinContrasena: {
            login_without_password: 'Entrar sem senha',
            title_seo: 'Inicie sem senha | OKdescontos',
            description_seo: 'Página de login sem senha',
        },
        login: {
            login: 'Conecte-se',
            no_account: 'Você não tem uma conta?',
            register_here: 'Cadastre-se aqui',
        },
        notificaciones: {
            notifications: 'Notificações',
            no_readed: 'Não lida',
            no_unreaded: 'Por enquanto você não tem notificações não lidas',
            readed: 'Lidas',
            no_notifications: 'Nenhuma notificaçãos',
            no_notifications_by_now: 'Por enquanto você não tem Notificações',
            title_seo: 'Notificações | OKdescontos',
            description_seo: 'Página para ver as notificações da sua conta',
            error: 'Ocorreu um erro ao carregar as notificaçõess',
        },
        nuevaContrasena: {
            new_password: 'Nova Senha',
            write_new_password: 'Escreva sua nova senha',
            repeat_new_password: 'Repita a senha',
            change_password: 'Mudar a senha',
            title_seo: 'Nova Senha | OKdescontos',
            description_seo: 'Página da geração de nova senha',
            close: 'Fechar',
            incorrect_fields: 'Existem campos incorretos, siga as instruções.',
            token_failed: 'O token está incorreto ou expirou',
        },
        olvideMiContrasena: {
            forgot_password: 'Esqueceu a senha?',
            title_seo: 'Esqueci minha senha | OKdescontos',
            description_seo: 'Página de recuperação de senha',
        },
        registro: {
            register: 'Cadastre-se',
            have_account: 'Você tem uma conta?',
            login_here: 'Faça login aqui',
        },
        search: {
            results: 'Resultados para',
            this: 'esta',
            sorry: 'Sinto muito! Não encontramos resultados para sua pesquisa',
            meanwhile: 'Enquanto isso, você pode conferir os melhores cupons aqui',
            what_are_looking: 'O que você está buscando?',
            easier: 'Facilite a sua pesquisa escolhendo uma dessas opções',
            related: 'relacionadas à sua pesquisa',
            offer_and_coupons: 'Ofertas e cupons',
            featured: 'destacados',
            recommend: 'Também recomendamos essas lojas onde você encontrará as melhores ofertas e cupons',
            results_for: 'Resultados da pesquisa por',
            of: 'de',
            todo: 'Tudo',
            offers: 'Ofertas',
            coupons: 'Cupons',
            brands: 'Lojas',
        },
        verificarCuenta: {
            request_failed: 'Sua solicitação não pode ser processada corretamente',
            redirect: 'Você será redirecionado para o início em segundos:',
            verified_success: 'Conta verificada com sucesso',
            enjoy: 'Agora aproveite as ofertas/cupons na OKdescontos',
            go_home: 'Ir para o inicio',
            go_profile: 'Ir para o meu perfil',
            error_on_validate: 'Não foi possível verificar sua conta',
            code_not_valid: 'Código de verificação inválido ou expirado',
            go_to_settings: 'Ir para Configurações',
            contact_support: 'Entre em contato com o suporte',
            verify_error: 'Ocorreu um erro ao tentar verificar sua conta',
            title_seo: 'Verificar conta | OKdescontos',
            description_seo: 'Página de verificação da conta do usuário',
        },
    },
}
